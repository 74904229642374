import './App.css';
import React, { useEffect } from 'react';

  const App = () => {
    useEffect(() => {
      // Get the current domain
      const currentDomain = window.location.origin;
      console.log(currentDomain);
      // Check if the domain is shop.example.com
      if (currentDomain === 'https://shop.thale.in') {
        // Redirect to the desired URL
        window.location.href = 'https://orders.thale.in';
      }
    }, [])
    return (
      <div>
       
       
      </div>
    );
  };

export default App;
